@import 'styles/_variables';

.AutoSuggestInput {
    position: relative;
    display: inline-block;

    &__input {
        width: 100%;
        height: 100%;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: $zIndex-AppModal;
        width: 100%;
        border-radius: 5px;
        background-color: white;

        &-item {
            display: block;
            width: 100%;
            padding: $spacing-half;
            font-size: 16px;
            font-weight: $font-semi-bold;
            color: $color-lightgray;

            &:hover {
                cursor: pointer;
                color: $color-dark-blue;
            }
        }
    }
}
