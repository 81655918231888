@import 'styles/_variables';

.SafeImage {
    $root: &;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    overflow: hidden;
    font-size: 16px;
    color: $color-lightgray;
    object-fit: cover;

    &--isRound {
        border-radius: 50%;
    }

    &--isSquareRound {
        border-radius: 5px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: inherit;
        overflow: hidden;
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $color-lightgray;
        font-size: inherit;
        font-weight: $font-bold;
        color: white;
    }
}
