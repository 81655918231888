@import 'styles/_variables';
@import 'styles/_mixins';

$padding: 8px;
$padding-xlg: 5px;
$border-width: 1px;
$border-type: solid;
$border-radius: 5px;
$height-countdown-bar: 4px;

// SUCCESS
$color-bg--success: white;
$color-text--success: $color-gray;
$color-border-success: transparent;
$color-close--success: $color-gray;
// INFO
$color-bg--info: white;
$color-text--info: $color-gray;
$color-border-info: transparent;
$color-close--info: $color-gray;
// WARNING
$color-bg--warning: white;
$color-text--warning: $color-gray;
$color-border-warning: transparent;
$color-close--warning: $color-gray;
// ERROR
$color-bg--error: white;
$color-text--error: $color-gray;
$color-border-error: transparent;
$color-close--error: $color-gray;

.AppAlertsCard {
    $root: &;

    display: inline-flex;
    align-items: center;
    padding: $padding;
    margin-bottom: 14px;
    max-width: 580px;
    background-color: #fff;
    border-width: $border-width;
    border-style: $border-type;
    border-radius: $border-radius;
    box-shadow: $box-shadow-medium;
    transition: transform;
    transition-timing-function: ease-out;
    transition-property: transform height;

    @include screen-xlg {
        max-width: 400px;
        margin-bottom: 10px;
        padding: $padding-xlg;
    }

    &--TOP_LEFT,
    &--BOTTOM_LEFT,
    &--TOP_RIGHT,
    &--BOTTOM_RIGHT,
    &--TOP_CENTER,
    &--BOTTOM_CENTER {
        &-init {
            transform: translateX(0) translateY(0) !important;
        }
    }

    &--TOP_LEFT,
    &--BOTTOM_LEFT {
        transform: translateX(-1080px);
    }

    &--TOP_RIGHT,
    &--BOTTOM_RIGHT {
        transform: translateX(1080px);
    }

    &--TOP_CENTER {
        transform: translateY(-1080px);
    }

    &--BOTTOM_CENTER {
        transform: translateY(1080px);
    }

    &--SUCCESS {
        color: $color-text--success;
        border-color: $color-border-success;
        background-color: $color-bg--success;
    }

    &--INFO {
        color: $color-text--info;
        border-color: $color-border-info;
        background-color: $color-bg--info;
    }

    &--WARNING {
        color: $color-text--warning;
        border-color: $color-border-warning;
        background-color: $color-bg--warning;
    }

    &--ERROR {
        color: $color-text--error;
        border-color: $color-border-error;
        background-color: $color-bg--error;
    }

    &__body {
        margin: 0 45px 0 15px;
        font-weight: $font-semi-bold;

        @include screen-xlg {
            margin: 0 30px 0 10px;
        }

        &-main {
            display: block;
            font-size: 30px;

            @include screen-xlg {
                font-size: 22px;
            }
        }

        &-sub {
            display: block;
            font-size: 20px;

            @include screen-xlg {
                font-size: 14px;
            }
        }
    }

    &__close {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 10px;
        cursor: pointer;
        font-size: 24px;

        @include screen-xlg {
            font-size: 16px;
            top: 10px;
            right: 10px;
            width: 8px;
        }

        #{$root}--SUCCESS & {
            color: $color-close--success;
        }

        #{$root}--INFO & {
            color: $color-close--info;
        }

        #{$root}--WARNING & {
            color: $color-close--warning;
        }

        #{$root}--ERROR & {
            color: $color-close--error;
        }
    }

    &__images {
        $images: &;

        position: relative;

        &--double {
            margin: 0 10px;
        }

        &-item {
            display: inline-block;

            &:nth-of-type(1) {
                #{$images}--double & {
                    transform: translate(-25%, -25%);
                }
            }

            &:nth-of-type(2) {
                position: absolute;
                top: 25%;
                left: 25%;
            }
        }
    }
}
