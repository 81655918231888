@import 'styles/_variables';
@import 'styles/_mixins';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zIndex-AppModal;
    background-color: rgba(0, 0, 0, 0.3);

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 850px;
        width: 1300px;
        background-color: white;
        outline: none;
        box-shadow: $box-shadow-medium;
        border-radius: 10px;
        overflow: hidden;
        // margin-top: 50px;

        @include screen-xlg {
            width: 910px;
            height: 600px;
        }

        &--customSize {
            width: auto;
            height: auto;

            @include screen-xlg {
                width: auto;
                height: auto;
            }
        }
    }
}
