//-------------------------------------//
// MEDIA QUERIES
$media-xlg: 1600px;
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette
// TODO_REPLACE(projects own color palette)\
$color-brand-blue: blue;
$color-dark-blue: #006eb8;
$color-electric-blue: #84ebff;
$color-light-blue: #b5e3ff;
$color-alice-blue: #e5f0f8;
$color-aliceblue: #e5f0f8;
$color-ultra-light-blue: #EBF7FF;

$color-green: #35d367;
$color-light-green: #00c800;

$color-red: #e63939;
$color-red2: #eb5757;
$color-grey1: #999;
$color-red-google: #fc5a5a;
$color-pink: #ec1c6d;
$color-lightgray: #58595b;
$color-dark-cerulean: #006eb8;

$color-gray: #333;
$color-lavender: #ededee;
$color-new-gray-6: #f2f2f2;
$color-gray-3: #828282;
$color-white: #FFF;

$color-body: #334150;
$color-link: $color-body;

$color-red-blue-gradient: linear-gradient(
    224.89deg,
    #eb1c6d 14.61%,
    #006eb8 85.17%
);

$background-color-body: #ececec;

$color-blue-facebook: #0062ff;
$color-red-google: #fc5a5a;

$color-live-class: $color-dark-blue;
$color-on-site-class: #9ee7a3;

//-------------------------------------//
// FONTS
//
$font-family-mont: 'Montserrat', 'Arial', 'Sans-Serif';

$font-family-body: $font-family-mont;
$font-color-body: $color-body;
$font-size-body: 14px;
$font-size-small: 10px;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-bolder: 900;

//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
$zIndex-AppModal: 1001;
$zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)
$border-radius-button: 4px;
$border-radius-small: 3px;
$border-radius-medium: 6px;
$border-radius-large: 9px;

// Box Shadow
// TODO_REPLACE(projects shadows)
$box-shadow-light: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$box-shadow-medium: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
$box-shadow-heavy: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)
// App Header Nav
$AppHeaderNav-height: (
    default: 95px,
    xlg: 65px,
);
$AppHeaderNav-z-index: $zIndex-AppHeaderNav;

// App Side Nav
// $AppSideNav-width: 240px;
// $AppSideNav-z-index: 160ms;

// ActivityIndicator
$ActivityIndicator-stroke: #bbb;

// CheckBox
$CheckBox-border: 2px solid $color-dark-blue;
$CheckBox-color: $color-dark-blue;
$CheckBox-checked-background-color: $color-dark-blue;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// ...
