@import 'styles/_variables';
@import 'styles/_mixins';

.Company {
    background-color: #EBF7FF;
    width: 100% !important;
    height: calc(100vh - 65px);
    display: flex;
    overflow: hidden;
    padding: 20px;

    &__Menu {
        &-add {
            margin-left: 20px;
            width: 90%;
            height: 90px;
            background: #FFF;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
            border-radius: 50px;
            display: flex;
            align-items: center;
            padding: 15px;
            cursor: pointer;
            margin-bottom: 40px;

            @include screen-xlg() {
                height: 64px;
                margin-bottom: 28px;
            }

            &-avatar {
                width: 60px;
                height: 60px;
                margin-left: 10px;

                @include screen-xlg() {
                    width: 42px;
                    height: 42px;
                }
            }

            &-text {
                font-size: 25px;
                line-height: 32px;
                color: #58595B;
                margin-left: 20px;

                @include screen-xlg() {
                    font-size: 17px;
                }
            }
        }

        &-publish {
            height: 96px;
            width: 100%;
            background-color: #EBF7FF;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 50px;

            &-btn {
                font-weight: 600;
                font-size: 25px;
                line-height: 32px;
                color: #fff;
                background: #006EB8;
                border-radius: 50px;
                width: 260px;
                height: 80px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            &-chevron {
                width: 30px !important;
                height: 20px;
                margin-left: auto;
                margin-right: 15px;
                color: #58595B;
                cursor: pointer;
            }

            &-icon {
                width: 30px;
                height: 30px;
                margin-right: 15px;
            }
        }

        &-dropdown {
            position: absolute;
            right: 0;
            width: 330px;
            height: calc(100vh - 400px);
            background: #FFF;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            padding: 10px 20px;
            overflow: hidden;

            @include screen-xlg() {
                width: 234px;
                height: calc(100vh - 320px);
            }

            &-search {
                height: 40px;
                font-size: 16px;
                line-height: 20px;
                color: #58595B;
                padding: 10px 20px;
                background: rgba(237, 237, 238, 0.5);
                border: 1px solid #58595B;
                box-sizing: border-box;
                border-radius: 10px;
                width: 100%;
                outline: none !important;
                margin-bottom: 10px;

                @include screen-xlg() {
                    height: 30px;
                    font-size: 12px;
                }

                &-icon {
                    position: absolute;
                    font-size: 16px;
                    right: 12px;
                    top: 14px;
                    cursor: pointer;

                    @include screen-xlg() {
                        right: 15px;
                        top: 9px;
                        font-size: 12px;
                    }
                }
            }

            &-items {
                overflow-y: scroll;
                height: calc(100% - 80px);
            }

            &-item {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #A5AFB6;
                padding: 10px 0;
                cursor: pointer;
            }

            &-avatar {
                width: 35px;
                height: 35px;
                margin-right: 12px;

                @include screen-xlg() {
                    width: 25px;
                    height: 25px;
                }
            }

            &-text {
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: #58595B;
                margin: 0;
                padding-left: 15px;

                @include screen-xlg() {
                    font-size: 12px;
                }
            }
        }

        &-item {
            height: 50px;
            padding-left: 26px;
            margin: 10px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 0 10px 10px 0;

            @include screen-xlg() {
                height: 36px;
            }

            &-avatar {
                width: 50px;
                height: 50px;
                border-radius: 10px;

                @include screen-xlg() {
                    width: 35px;
                    height: 35px;
                }
            }

            &-text {
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: #58595B;
                margin-left: 15px;

                @include screen-xlg() {
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            &-icon {
                width: 25px !important;
                height: 25px;
                color: #58595B;
                margin: 0 10px;

                @include screen-xlg() {
                    width: 18px !important;
                    height: 18px;
                    margin: 0 8px;
                }
            }

            &-chevron {
                width: 30px;
                height: 30px;
                margin-left: auto;
                margin-right: 15px;
                color: #58595B;

                @include screen-xlg() {
                    width: 16px !important;
                    height: 16px !important;
                }
            }
        }

        .-is-active {
            background-color: #006EB8;
            color: #fff !important;

            .Company__Menu-item-icon {
                color: #fff;
            }

            .Company__Menu-item-text {
                color: #fff;
            }
        }

        .-has-border {
            border: 1px solid #006EB8;
        }
    }

    &__Content {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        right: 0;

        &-root {
            width: 100%;
            height: calc(100vh - 100px);
            background-color: #fff;
            box-shadow: 0 -2px 24px rgba(0, 0, 0, 0.15);
            border-radius: 33px;
        }

        &-history div {
            &:nth-child(odd) {
                background: #EDEDEE;
            }
        }

        &-classes {
            flex: 1;
            padding: 40px 26px;
            min-width: 500px;

            &-title {
                font-weight: 600;
                font-size: 17px;
                line-height: 22px;
                color: #58595B;
                display: inline-block;
                border-bottom: 2px solid #58595B;

                @include screen-xlg() {
                    font-size: 14px;
                }
            }

            &-container {
                align-items: baseline;
                max-height: 450px;
            }
        }

        &-header {
            height: 100px;
            background: #006EB8;
            border-radius: 33px 33px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include screen-xlg() {
                height: 71px;
            }

            &-title {
                font-weight: 600;
                font-size: 35px;
                line-height: 45px;
                color: #fff;
                margin: 0;

                @include screen-xlg() {
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 500;
                }
            }

            &-subtitle {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: #fff;
                margin: 0;

                @include screen-xlg() {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                }
            }

            &-action {
                width: 140px;
                margin: 20px;
                border-radius: 50px;

                img {
                    width: 20px;
                    height: 20px;
                }

                @include screen-xlg() {
                    width: 100px;
                    height: 50px;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &-loading {
                margin-top: 35px;
                text-align: center;
            }
        }

        &-form {
            max-height: calc(100% - 100px);
            overflow-y: scroll;

            &-info {
                width: 75%;
                //padding: 0 10px;

                @include screen-xlg() {
                    width: 70%;
                }
            }

            &-contacts {
                width: 25%;
                padding: 10px;

                @include screen-xlg() {
                    width: 30%;
                }
            }

            &-keyword {
                width: 100%;
                padding: 20px;
                display: flex;
            }
        }

        &-item {
            //min-width: 900px;
            min-height: 100px;
            margin: 10px 20px;
            display: flex;
            align-items: flex-start;

            &-avatar {
                width: 100px;
                height: 100px;
                margin-right: 30px;
                border: 1px dotted $color-dark-blue;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;

                @include screen-xlg() {
                    width: 71px;
                    height: 71px;
                    margin-right: 25px;
                }

                &.-team {
                    width: 100px;
                    height: 50px;

                    @include screen-xlg() {
                        width: 71px;
                        height: 40px;
                        margin-right: 25px;

                        img {
                            width: 20px;
                        }
                    }
                }

                &-no-border {
                    border: none;

                    img {
                        border: none;
                    }
                }
            }

            &-avatar-icon {
                max-width: 80px;
            }

            &-avatar-icon-border {
                border: 1px dotted $color-dark-blue;
                border-radius: 10px;
            }

            &-avatar-upload {
                width: 40px;
                height: 40px;
                position: absolute;
                bottom: -10px;
                right: -10px;
                cursor: pointer;

                @include screen-xlg() {
                    width: 28px !important;
                    height: 28px;
                }
            }

            &-title {
                font-weight: 600;
                font-size: 17px;
                line-height: 22px;
                color: #58595B;
                margin-top: 0;
                margin-bottom: 5px;
            }

            &-subtitle {
                font-weight: 600;
                font-size: 17px;
                line-height: 22px;
                color: #BDBDBD;
                margin: 10px 15px 20px 15px;

                @include screen-xlg() {
                    font-size: 12px;
                }
            }

            &-admin {
                font-weight: 600;
                font-size: 17px;
                line-height: 22px;
                color: #BDBDBD;
                margin-left: 50px;
                margin-right: 22px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            &-text {
                font-size: 17px;
                font-family: $font-family-mont;
                line-height: 20px;
                color: #58595B;
                margin-bottom: 20px;
                padding: 10px 20px;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                width: 350px;
                height: 50px;
                outline: none !important;
                border: none;

                @include screen-xlg() {
                    width: 250px;
                    height: 35px;
                    font-size: 12px;
                }

                span {
                    padding: 0;
                    color: $color-gray;
                }
            }

            &-datepicker {
                margin-bottom: 25px;

                .DateTimePicker__header-month {
                    max-width: 60%;
                    box-shadow: none;
                }

                .DateTimePicker__header-controls {
                    box-shadow: none;
                }

                .react-datepicker {
                    box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                }

                .AppInput__input-container {
                    display: flex;
                    align-items: center;

                    @include screen-xlg() {
                        align-items: flex-end;
                    }
                }

                .AppInput__right {
                    height: auto;
                    padding: 0;
                    box-shadow: none;
                    justify-content: flex-end;
                }

                span {
                    width: 300px;
                    height: 50px;
                    padding: 8px 16px;
                    color: #58595B !important;
                    font-weight: 400;
                    font-family: $font-family-mont;
                    box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);

                    @include screen-xlg() {
                        width: 213px;
                        height: 35px;
                    }
                }

                input {
                    padding: 0;
                    font-family: $font-family-mont;

                    @include screen-xlg() {
                        font-size: 12px;
                    }
                }
            }

            &-textarea {
                font-size: 16px;
                font-family: $font-family-mont;
                line-height: 20px;
                color: #58595B;
                margin: 5px 0;
                padding: 10px 20px;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                min-width: 100%;
                outline: none !important;
                border: none;
                height: 160px;
                resize: none;

                @include screen-xlg() {
                    font-size: 12px;
                    height: 150px;
                }
            }

            &-skills {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            &-keyword {
                height: 50px;
                font-size: 17px;
                line-height: 22px;
                color: #58595B;
                margin: 5px;
                padding: 10px 40px 10px 20px;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                outline: none !important;
                border: none;
                position: relative;

                @include screen-xlg() {
                    height: 35px;
                    font-size: 12px;

                    input {
                        font-size: 12px !important;
                    }
                }

                &-delete {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    display: none;
                    cursor: pointer;
                }

                &:hover {
                    .Company__Content-item-keyword-delete {
                        display: block !important;

                        @include screen-xlg() {
                            height: 25px;
                            top: 8px;

                            svg {
                                width: 12px !important;
                                height: 12px !important;
                            }
                        }
                    }
                }

                &-new {
                    width: 150px !important;

                    @include screen-xlg() {
                        height: 35px;
                        font-size: 12px !important;
                    }
                }
            }

            &-add {
                width: 50px;
                height: 50px;
                font-size: 25px;
                color: #58595B;
                margin: 5px;
                padding: 4px;
                text-align: center;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                cursor: pointer;

                @include screen-xlg() {
                    width: 35px;
                    height: 35px;
                    padding: 0;
                }
            }
        }

        &-contacts {
            min-width: 100px;
            padding-left: 20px;
            padding-right: 10px;
            border-left: 1px dashed #999;
            margin-bottom: 20px;

            &-title {
                font-weight: 600;
                font-size: 17px;
                line-height: 22px;
                color: #58595B;
                margin-top: 0;
                margin-left: 40px;
            }

            &-icon {
                width: 22px;
                height: 22px;
                margin-right: 10px;

                &-pin {
                    width: 50px !important;

                    @include screen-xlg() {
                        width: 34px !important;
                    }
                }

                @include screen-xlg() {
                    width: 15px;
                    height: 15px;
                }
            }

            &-text {
                font-size: 16px;
                font-family: $font-family-mont;
                line-height: 20px;
                color: #58595B;
                margin: 5px 0;
                padding: 10px 20px;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                width: 100%;
                height: 50px;
                outline: none !important;
                border: none;

                @include screen-xlg() {
                    width: 250px;
                    height: 35px;
                    font-size: 12px;
                }

                &.-email {
                    background: $color-new-gray-6;
                }
            }

            &-input-prefix {
                width: 100%;
                height: 50px;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                border: none;
                font-size: 12px;
                font-family: $font-family-mont;
                line-height: 22px;
                padding: 0 10px;
                display: flex;
                align-items: center;

                @include screen-xlg() {
                    height: 35px;
                }

                input {
                    width: 60%;
                    border: none;
                    color: #58595B;
                }

                &-start {
                    max-width: 20px;
                    margin-right: 5px;
                    //margin-left: 15px;
                }

                &-end {
                    margin-top: 7px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    &-flag-image {
                        width: 20px;
                        max-height: 20px;
                        margin-right: 10px;
                    }

                    &-select {
                        max-width: 20px;
                        background: transparent;

                        input {
                            display: none;
                        }
                    }
                }
            }

            &-subtext {
                font-size: 16px;
                font-family: $font-family-mont;
                line-height: 20px;
                color: #58595B;
                margin: 5px 0;
                padding: 10px 20px;
                background: #FFF;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 8px;
                max-width: 45%;
                height: 50px;
                outline: none !important;
                border: none;

                @include screen-xlg() {
                    height: 35px;
                    font-size: 12px;
                }

                input,
                span {
                    padding: 0;
                    background: transparent;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    font-family: $font-family-mont;

                    @include screen-xlg() {
                        font-size: 12px;
                    }
                }
            }

            &-suggestion-input {
                //min-width: 150px;
                padding: 0;
                height: 35px;
                box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
                border-radius: 10px;

                span {
                    border: none !important;
                    background: $color-white;
                    border-radius: 8px;
                }

                input {
                    padding: 10px;
                    font-weight: 400;
                    font-size: 12px;
                }

                .AppInput--gray {
                    color: $color-body;
                }

                .AppInput--focused {
                    background-color: $color-white !important;
                }

                .AutoSuggestInput__dropdown {
                    box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);

                    .AutoSuggestInput__dropdown-item {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }
        }

        &-information {
            min-width: 100px;
            padding-left: 40px;
            padding-right: 10px;
            margin-bottom: 30px;
        }
    }

    &__Modal {
        min-width: 80%;

        &-bar {
            margin-top: 30px;

            button {
                //max-height: 35px;
                //background: $color-new-gray-6;
            }

            &-selected {
                background: $color-white !important;
            }
        }

        &-content {
            max-height: 300px;
            max-width: 700px;
            margin: 50px auto 10px auto;
            padding: 20px;
            overflow-y: scroll;
            flex-wrap: wrap;
            display: flex;

            &-input {
                width: 300px;
                margin: 10px;
                display: flex;
                align-items: center;
            }

            &-button {
                width: 50px;
                height: 50px;
                margin-top: 10px;

                @include screen-xlg() {
                    width: 35px;
                    height: 35px;
                    margin-top: 15px;
                }

                button {
                    padding: 5px;
                    border: none;
                    box-shadow: 3px 3px 10px 0 rgba(214, 206, 214, 0.8);

                    @include screen-xlg() {
                        font-size: 14px;
                    }
                }
            }
        }

        &-find-content {
            height: 250px;
            max-width: 700px;
            margin: 50px auto 10px auto;
            padding: 20px;
            overflow-y: scroll;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;

            &-input-box {
                width: 90%;
            }

            &-input {
                width: 90%;
                display: flex;
                align-items: flex-start;
            }

            .input {
                width: 100%;
            }

            img {
                margin-right: 20px;
            }
        }

        &-ok-btn {
            margin-top: 20px !important;
        }
    }
}

.Company-search {
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;

    &__col {
        flex: 1;
        margin-left: 30px;
        margin-right: 30px;
        position: relative;
        min-width: 480px;
        padding-bottom: 50px;

        &-empty {
            position: relative;
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            color: #828282;
            margin: 50px 0;
        }
    }

    &__input {
        width: 301px;
    }

    &__all {
        font-size: 20px;
        line-height: 16px;
        color: #58595B;
    }

    &__list {
        height: calc(100vh - 350px);
        overflow: scroll;

        &-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            min-width: 49%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 60px;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 9px 18px;

            &:nth-child(4n) {
                background: #EFEFEF;
            }

            &:nth-child(4n - 1) {
                background: #EFEFEF;
            }
        }

        &-avatar {
            border-radius: 50%;
            object-fit: cover;
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        &-name {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
            color: #000;
            margin-top: 0;
        }

        &-coach {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 11px;
            line-height: 14px;
            color: #58595B;
        }

        &-add {
            width: 60px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #999;
            color: #999;
            font-size: 11px;
            line-height: 11px;
            border-radius: 5px;
            cursor: pointer;
        }

        &-header {
            background: #999;
            border-radius: 5px;
            height: 35px;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 20px;
            color: #fff;
            font-weight: 800;
            font-size: 20px;
            line-height: 20px;
            margin-top: 20px;
            margin-bottom: 13px;
            text-transform: capitalize;
        }
    }
}

.User {
    &__content {
        width: 82%;
        position: absolute;
        padding: 20px;
        right: 0;

        &-form {
            width: 60%;
            min-width: 700px;
            margin-bottom: 50px;

            &-info {
                width: 65%;
                border-right: 2px dashed #58595B;
            }

            &-contacts {
                width: 35%;
                padding: 0 20px;

                &-title {
                    font-size: 20px;
                    color: #58595B;

                    @include screen-xlg() {
                        font-size: 17px;
                        font-weight: 600;
                    }
                }

                &-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                }

                &-remove {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 19px;
                    color: #58595B;
                    margin-top: 0;
                    margin-bottom: 0;
                    cursor: pointer;

                    @include screen-xlg() {
                        font-size: 11px;
                        line-height: 13px;
                    }

                    img {
                        width: 23px;
                        height: 23px;

                        @include screen-xlg() {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        &-divider {
            height: 230px;
            width: 2px;
            display: flex;
            border: 1px dashed #58595B;
            margin-right: 23px;
            margin-left: 23px;
        }

        &-avatar {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            border: 1px dotted $color-dark-blue;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;

            &-no-border {
                border: none !important;

                img {
                    border: none !important;
                }
            }

            &-icon {
                width: 30px !important;
                height: 30px;
                border-radius: 0 !important;
            }

            img {
                object-fit: cover;
                width: 100%;
                border-radius: 20px;
            }
        }

        &-datepicker {
            margin-bottom: 0 !important;
            display: flex;
            align-items: center;

            span {
                width: 190px;

                @include screen-xlg() {
                    width: 120px;
                }

                .AppInput__right {
                    width: 10%;
                }
            }
        }

        &-input {
            width: 170px;
            height: 50px;
            background: #FFF;
            box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
            border-radius: 8px;
            padding: 10px 20px;
            border: none;
            font-size: 12px;
            font-family: $font-family-mont;
            line-height: 22px;
            color: #58595B;
            margin-right: 10px;

            @include screen-xlg() {
                width: 100px;
                height: 35px;
                font-size: 12px;
            }

            &.-small {
                width: 100%;

                input,
                span {
                    padding: 0;
                    background: transparent;
                    font-weight: 500;
                }
            }

            &.-large {
                width: 100%;
            }

            &.-email {
                font-size: 10px;
                margin-right: 0;
                background: $color-new-gray-6;
            }

            &.-suggestion {
                height: 50px;
                min-width: 90px;
                padding: 0;

                .AppInput__input-container-input {
                    padding: 10px;
                    font-family: $font-family-mont;
                }

                @include screen-xlg() {
                    height: 35px;
                }
            }
        }

        &-input-prefix {
            width: 170px;
            height: 50px;
            background: #FFF;
            box-shadow: 1px 4px 10px rgba(69, 69, 69, 0.2);
            border-radius: 8px;
            padding: 10px 20px;
            border: none;
            font-size: 12px;
            font-family: $font-family-mont;
            line-height: 22px;
            color: #58595B;
            margin-right: 10px;
        }

        &-since {
            font-weight: 600;
            font-size: 12px;
            line-height: 22px;
            color: #BDBDBD;
            margin: 10px 15px;
            display: flex;
            align-items: center;

            @include screen-xlg {
                margin: 10px;
            }
        }

        &-admin {
            display: flex;
            align-items: center;
            margin-left: 15px;

            &.-not-allowed {
                cursor: not-allowed;
            }
        }

        &-textarea {
            height: 140px;
            width: 100%;
            background: #FFF;
            box-shadow: 1px 2px 10px rgba(69, 69, 69, 0.2);
            border-radius: 8px;
            resize: none;
            border: none;
            margin-top: 30px;
            padding: 15px;
            font-family: $font-family-mont;
        }
    }
}

.Classes {
    $border-radius: 15px;
    $image-size: 50px;
    $image-size-xlg: 35px;

    &-empty-chart {
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $color-gray-3;
    }

    &-chart {
        height: 100%;
        padding: $spacing-half;

        &--noData {
            height: auto;
            font-weight: $font-bold;
            color: $color-gray-3;
        }

        &-bar {
            display: inline-flex;
            align-items: flex-end;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: $border-radius $border-radius 0 0;
            overflow: hidden;
            background: $color-red-blue-gradient;
            color: white;
            font-weight: $font-semi-bold;
            font-size: 20px;

            @include screen-xlg {
                font-size: 14px;
            }
        }

        &-label {
            &-image {
                $size: 40px;
                $size-xlg: 28px;

                margin: auto;
                border-radius: 50%;
                width: $size;
                height: $size;
                object-fit: cover;

                @include screen-xlg {
                    width: $size-xlg;
                    height: $size-xlg;
                }
            }

            &-text {
                font-weight: $font-semi-bold;
                font-size: 14px;
                color: $color-lightgray;
            }
        }
    }

    height: 350px;
    margin-right: 20px;

    &__column {
        background: linear-gradient(224.89deg, #EB1C6D 14.61%, #006EB8 85.17%);
        border-radius: 15px 15px 0 0;
        width: 95px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        font-size: 21px;
        line-height: 27px;

        @include screen-xlg() {
            width: 67px;
            font-size: 15px;
        }
    }

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 13px;
    }

    &__content {
        height: 100%;
        display: inline-block;
        text-align: center;
    }
}

.History {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 0 20px;

    &__text {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #828282;
        margin-right: 20px;

        @include screen-xlg() {
            font-size: 12px;
        }
    }

    &__avatar {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        margin-right: 10px;

        @include screen-xlg() {
            width: 26px;
            height: 26px;
        }
    }
}

.InviteEmailModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 800px;
        padding: 20px;
        background-color: white;
        outline: none;
        box-shadow: $box-shadow-medium;
        border-radius: 10px;
        overflow: hidden;

        &-close {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 20px;
            color: $color-red;
            cursor: pointer;
        }

        &-title {
            text-align: center;

            &-main {
                font-size: 32px;
                font-weight: $font-semi-bold;
                color: $color-lightgray;
            }

            &-sub {
                font-size: 16px;
                font-weight: $font-semi-bold;
                color: $color-gray;
            }
        }

        &-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: $spacing;
            column-gap: 10%;
            justify-content: space-between;
            margin: $spacing*2 0 0 0;

            &-input {
                width: 100%;
                max-width: 300px;
                margin: auto;
            }
        }

        &-buttons {
            width: 100%;
            margin: $spacing 0 0 0;
            text-align: center;

            &-button {
                font-weight: $font-semi-bold;

                &:first-of-type {
                    margin: 0 $spacing 0 0;
                }
            }
        }
    }
}
