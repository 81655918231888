*,
*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html,
body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    background-color: $background-color-body;
}

body {
    line-height: 1.5;
    font-size: $font-size-body;
    font-family: $font-family-body;
    color: $font-color-body;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjus: 100%;
}

h1, h2, h3, h4, h5, h6 { /* stylelint-disable-line */
    margin: 0;
}

img {
    display: block;
}

a {
    text-decoration: none;
    outline: none;
    color: $color-link;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

input {
    font-family: inherit;
}

textarea {
    font-family: inherit;
}
