@import 'styles/_variables';
@import 'styles/_mixins';

.Card {
    $root: &;

    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 $spacing $spacing 0;
    vertical-align: middle;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
    color: $color-dark-blue;
    font-size: 50px;
    width: 200px;
    height: 250px;

    @include screen-xlg {
        width: 140px;
        height: 175px;
        font-size: 36px;
    }

    &--small {
        width: 155px;
        height: 190px;

        @include screen-xlg {
            width: 110px;
            height: 134px;
        }
    }

    &--add {
        cursor: pointer;
    }

    &--disabled {
        background-color: #f2f2f2;
    }

    &--isClickable {
        cursor: pointer;
    }

    &--isLoading {
        opacity: 0.6;
    }

    &--highlighted {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 14px 0 $color-dark-blue;

        @include screen-xlg {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 9px 0 $color-dark-blue;
        }
    }

    &__logo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;

        @include screen-xlg {
            width: 100px;
            height: 100px;
        }

        &--small {
            width: 110px;
            height: 110px;

            @include screen-xlg {
                width: 78px;
                height: 78px;
            }
        }

        &-loading {
            position: absolute;
            stroke: $color-lightgray;
            opacity: 0.5;
        }

        &-image {
            width: 100%;
            height: 100%;

            &-rounded {
                border-radius: 50%;
            }

            #{$root}--roundImage & {
                border-radius: 50%;
            }
        }
    }

    &__text {
        font-size: 21px;
        font-weight: $font-semi-bold;
        color: $color-lightgray;
        margin: $spacing-half 0 0 0;
        text-align: center;

        @include screen-xlg {
            font-size: 15px;
        }

        p {
            margin: 0;
        }
    }

    &__discount {
        position: absolute;
        right: 4px;
        bottom: -47px;
        width: fit-content;
        font-size: 16px;

        img {
            position: relative;
            width: 56px;
            right: 4px;
        }

        p {
            height: 36px;
            width: 52px;
            background-color: white;
            box-shadow: $box-shadow-light;
            padding: 0;
            border: 1px solid #fff;
            border-radius: 0 0 40px 40px;
        }
    }

    &__options {
        $width: 40px;

        position: absolute;
        top: 5px;
        right: $spacing - $width/1.5;
        display: inline-block;
        text-align: center;
        font-size: 20px;
        color: $color-lightgray;

        &-icon {
            position: relative;
            width: $width !important;
            cursor: pointer;
        }

        &-items {
            z-index: 1;
            position: absolute;
            right: 30px;
            padding: $spacing-half $spacing-half;
            transform: translate(0, -50%);
            color: #333;
            font-size: 16px;
            font-weight: $font-medium;
            border-radius: 5px;
            background-color: white;
            white-space: nowrap;
            text-align: left;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);

            @include screen-xlg {
                font-size: 11px;
            }

            &-item {
                padding: $spacing-half 0;
                cursor: pointer;
                border-bottom: 1px solid $color-lightgray;

                &:last-of-type {
                    border: none;
                }

                &-label {
                    margin-left: $spacing-half;
                }
            }
        }
    }
}
