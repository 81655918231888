@import 'styles/_variables';
@import 'styles/_mixins';

.AppInput {
    $root: &;
    $transition-duration: 0.5s;
    $padding-sides: 18px;
    $padding-sides-xlg: 14px;

    $themes: (
        primary: (
            color: white,
            bg: transparent,
            border: 1px solid white,
            focus-color: white,
            focus-bg: transparent,
            focus-border: 1px solid white,
            shadow: none,
        ),
        gray: (
            color: #828282,
            bg: #f2f2f2,
            border: none,
            focus-color: #828282,
            focus-bg: #f2f2f2,
            focus-border: none,
            shadow: none,
        ),
        light-gray: (
            color: $color-lightgray,
            bg: rgba(237, 237, 238, 0.5),
            border: 1px solid $color-lightgray,
            focus-color: $color-lightgray,
            focus-bg: rgba(237, 237, 238, 0.5),
            focus-border: 1px solid $color-lightgray,
            shadow: none,
        ),
        transparent-gray: (
            color: #828282,
            bg: transparent,
            focus-color: #828282,
            focus-bg: transparent,
            focus-border: none,
            border: 1px solid $color-lightgray,
        ),
        primary-border: (
            color: $color-lightgray,
            bg: white,
            border: 1px solid $color-lightgray,
            focus-color: $color-lightgray,
            focus-bg: white,
            focus-border: 1px solid $color-lightgray,
            shadow: none,
        ),
        borderless: (
            color: black,
            bg: white,
            border: none,
            focus-color: black,
            focus-bg: white,
            focus-border: none,
            shadow: none,
        ),
        blue: (
            color: $color-dark-cerulean,
            bg: $color-alice-blue,
            border: none,
            focus-color: $color-dark-cerulean,
            focus-bg: $color-alice-blue,
            focus-border: none,
            shadow: none,
        ),
        card: (
            bg: white,
            color: black,
            focus-color: black,
            focus-bg: white,
            shadow: $box-shadow-medium,
            border: $border-radius-button,
        ),
        white-bordered: (
            bg: white,
            color: #58595b,
            focus-color: #58595b,
            focus-bg: white,
            shadow: none,
            border: 1px solid #bdbdbd,
        ),
    );

    position: relative;
    display: inline-flex;
    border: none;
    border-radius: $border-radius-medium;
    overflow: hidden;
    font-size: 16px;
    font-weight: $font-semi-bold;
    transition:
        color $transition-duration,
        background-color $transition-duration,
        border $transition-duration;
    cursor: text;

    @include screen-xlg {
        font-size: 14px;
    }

    &--disabled {
        cursor: no-drop;

        * {
            cursor: no-drop;
        }
    }

    &__left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        user-select: none;
        color: inherit;
        cursor: pointer;

        * {
            cursor: pointer;
        }
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        user-select: none;
        color: inherit;
        cursor: pointer;

        * {
            cursor: pointer;
        }
    }

    &__input-container {
        display: flex;
        flex-grow: 1;

        &-input {
            width: 100%;
            outline: none;
            border: none;
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
            background-color: transparent;
            padding: 16px $padding-sides;

            @include screen-xlg {
                padding: 10px $padding-sides-xlg;
            }
        }
    }

    @each $label, $theme in $themes {
        $bg-color: map-get($theme, 'bg');
        &--#{$label} {
            color: map-get($theme, 'color');
            background: $bg-color;
            box-shadow: map-get($theme, 'shadow');
            border: map-get($theme, 'border');

            &#{$root}--border {
                border: map-get($theme, 'border');

                &#{$root}--underlined {
                    border: none;

                    #{$root}__input-container {
                        border: none;
                        border-bottom: map-get($theme, 'border');
                    }
                }
            }

            &#{$root}--focus {
                &#{$root}--focused {
                    color: map-get($theme, 'focus-color');
                    background-color: map-get($theme, 'focus-bg');

                    &#{$root}--border {
                        border: map-get($theme, 'focus-border');
                    }
                }
            }

            #{$root}__input-container {
                &-input {
                    &:-webkit-autofill,
                    &:-webkit-autofill:focus {
                        @if ($bg-color == transparent) {
                            box-shadow: 0 0 0 1000px $color-pink inset;
                            -webkit-text-fill-color: white;
                        }

                        @else {
                            box-shadow: 0 0 0 1000px $bg-color inset;
                            -webkit-text-fill-color: map-get($theme, 'color');
                        }
                    }
                }
            }
        }
    }
}
