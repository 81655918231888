@import 'styles/_variables';
@import 'styles/_mixins';

#root,
.AppContainer {
    height: 100%;
    min-height: 100%;
}

.AppContainer {
    $root: &;

    &__container {
        height: 100%;
        min-height: 100%;

        #{$root}--withHeaderNav & {
            @include header-height('padding-top');
        }
    }

    //
    // &--withSideNav {
    //     padding-left: $AppSideNav-width;
    // }
}
