/*
 *
 * <ActivityIndicator />
 *
 */
.ActivityIndicator {
    stroke: $ActivityIndicator-stroke;
    vertical-align: middle;
}

/*
 *
 * <Avatar />
 *
 */
.Avatar {
    color: $color-lightgray;
}

/*
 *
 * <Button />
 *
 */
.Button {
    transition-duration: 100ms;

    &--default {
        &:hover {
            box-shadow: $box-shadow-light;
        }

        &:active {
            color: $color-brand-blue;
            background-color: #fff;
            box-shadow: inset 0 0 3px rgba(#000, 1);
        }
    }

    &--default.Button--processing {
        color: $color-brand-blue;
        background-color: #fff;
        box-shadow: inset 0 0 3px rgba(#000, 1);
    }

    &--solid-blue {
        border-radius: $border-radius-button;
        color: #fff;
        background-color: $color-brand-blue;
    }
}

.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;
    //transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0 0 5px 5px #0099E0;
}

/*
 *
 * <CheckBox />
 *
 */
// .CheckBox {
//     &__box {
//         border: $CheckBox-border;
//         color: $CheckBox-color;

//         &--checked {
//             background-color: $CheckBox-checked-background-color;
//         }
//     }
// }

/*
 *
 * <RadioBox />
 *
 */
// .RadioBox {
//     &__box {
//         border: $RadioBox-border;
//
//         &:hover {
//             border: $RadioBox-hover-border;
//             background-color: $RadioBox-hover-background-color;
//         }
//
//         &--checked {
//             border: $RadioBox-checked-border !important;
//             background-color: $RadioBox-checked-background-color !important;
//         }
//     }
// }

// ...
