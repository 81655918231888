@import 'styles/_variables';

.ProfileImage {
    &__content {
        width: 50px;
        height: 50px;
        border-radius: 50% !important;
        background: $color-light-blue;
        color: $color-dark-blue;
        text-align: center;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
