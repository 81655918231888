@import 'styles/_variables';
@import 'styles/_mixins';

.AppHeaderNav {
    $root: &;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 $spacing;
    z-index: $AppHeaderNav-z-index;
    background: #fff;
    box-shadow: 0 1px 15px -5px $color-lightgray;
    user-select: none;
    transition: background 200ms;

    @include header-height('height');

    &--isSub {
        background: linear-gradient(270deg, #FFF 0.74%, #006EB9 100%);
    }

    &--isPublish {
        background: $color-ultra-light-blue;
        box-shadow: none;
    }

    &__publish {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &-dropdown {
            color: $color-lightgray;
            margin: 0 $spacing 0 0;
            font-size: 25px;

            @include screen-xlg {
                font-size: 16px;
            }
        }

        &-button {
            height: 65px;
            font-size: 25px;

            @include screen-xlg {
                height: 45px;
                font-size: 16px;
            }

            &-icon {
                width: 35px;
                height: 35px;
                margin: 0 $spacing-half 0 0;

                @include screen-xlg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }

    &__company {
        position: relative;
        height: 100%;

        &-main {
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;

            #{$root}--no-drop & {
                cursor: default;
            }

            &-logo {
                #{$root}--no-company & {
                    width: 225px;
                    height: 55px;
                    border-radius: 0;

                    @include screen-xlg {
                        width: 155px;
                        height: 38px;
                    }
                }
            }

            &-name {
                margin: 0 0 0 $spacing;
                font-size: 24px;
                font-weight: $font-semi-bold;
                color: #333;
                text-transform: uppercase;

                @include screen-xlg {
                    font-size: 17px;
                }
            }

            &-drop {
                margin: 0 0 0 $spacing-half;
                text-align: center;
                font-size: 26px;
                color: #333;

                @include screen-xlg {
                    font-size: 20px;
                }

                #{$root}--menu-open & {
                    &-icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &-menu {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(0, 100%);
            width: 440px;
            max-height: 400px;
            overflow: auto;
            padding: $spacing-half $spacing;
            background-color: white;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

            @include screen-xlg {
                width: 300px;
                max-height: 280px;
            }

            &-item {
                display: flex;
                align-items: center;
                padding: $spacing-half;
                border-bottom: 0.5px solid rgba(gray, 0.5);
                cursor: pointer;

                &:last-child {
                    border-bottom: none;
                }

                &-image {
                    flex-shrink: 0;
                }

                &-text {
                    margin: 0 0 0 $spacing;
                    font-size: 20px;
                    color: black;
                    text-transform: uppercase;

                    @include screen-xlg {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 40px;
        color: white;

        @include screen-xlg {
            font-size: 28px;
        }

        &-back {
            font-size: 24px;
            margin: 0 30px 0 0;
            line-height: 0;
            color: inherit;

            @include screen-xlg {
                font-size: 17px;
                margin: 0 20px 0 0;
            }
        }

        &-apps {
            position: relative;
            font-size: 24px;
            margin: 0 30px 0 0;

            @include screen-xlg {
                font-size: 17px;
                margin: 0 20px 0 0;
            }

            &-icon {
                cursor: pointer;
            }

            &-drop {
                position: absolute;
                top: 0;
                left: 0;
                padding: $spacing-half;
                box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                background-color: white;

                &-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $color-dark-blue;
                    font-size: 20px;

                    @include screen-xlg {
                        font-size: 14px;
                    }

                    &-name {
                        white-space: nowrap;
                        margin: 0 10px 0 0;

                        &-icon {
                            margin: 0 10px 0 0;
                        }
                    }

                    &-close {
                        cursor: pointer;
                    }
                }

                &-list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: $spacing-half 0 0 0;

                    &-item {
                        padding: 8px;
                        background: #FFF;
                        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;
                        margin: 0 0 0 5px;

                        &:first-of-type {
                            margin: 0;
                        }

                        &-image {
                            width: 50px;
                            height: 50px;

                            @include screen-xlg {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__photo {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        object-fit: cover;
        font-size: 40px;
        cursor: pointer;

        @include screen-xlg {
            width: 38px;
            height: 38px;
            font-size: 28px;
        }
    }
}
